<template>
  <div class="couponList">
    <ul class="listBox" v-if="type == 1">
      <li class="item" v-for="(item, index) in listCoupon" :key="index">
        <van-row type="flex" align="center" class="infoBox">
          <van-col class="infoBoxLeft">
            <div class="amount">
              <span class="symbol">¥</span>{{ item.DiscountAmount }}
            </div>
            <div class="amount-right">
              <div class="condition" v-if="!item.IsNoThreshold">
                {{ $t.couponInterpret.condition(item.UseCondition) }}
              </div>
              <div class="condition" v-else>
                {{ $t.couponInterpret.noCondition }}
              </div>
              <div class="date">
                {{ $t.couponInterpret.endDate }} : {{ item.MaturityTime }}
              </div>
            </div>
          </van-col>
          <van-col class="infoBoxRigth">
            <van-button class="getBtn" @click="goSearch">
              {{ $t.couponInterpret.goUse }}
            </van-button>
          </van-col>
        </van-row>
      </li>
    </ul>

    <ul class="listBox" v-if="type == 2 || type == 3">
      <li class="item" v-for="(item, index) in listCoupon" :key="index">
        <van-row type="flex" align="center" class="usedBox">
          <van-col class="usedBoxLeft">
            <div class="amount">
              <span class="symbol">¥</span>{{ item.DiscountAmount }}
            </div>
            <div class="amount-right">
              <div class="condition" v-if="!item.IsNoThreshold">
                {{ $t.couponInterpret.condition(item.UseCondition) }}
              </div>
              <div class="condition" v-else>
                {{ $t.couponInterpret.noCondition }}
              </div>
              <div class="date">
                {{ $t.couponInterpret.endDate }} : {{ item.MaturityTime }}
              </div>
            </div>
          </van-col>
          <van-col class="usedBoxRigth">
            <div class="usedTxt" v-if="type == 2">
              {{ $t.couponInterpret.state2 }}
            </div>
            <div class="usedTxt" v-if="type == 3">
              {{ $t.couponInterpret.state3 }}
            </div>
          </van-col>
        </van-row>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Button, Icon, Row, Col } from "vant";
Vue.use(Button).use(Row).use(Col).use(Icon);

export default {
  name: "CouponList",
  props: ["listCoupon", "type"],
  components: {},
  data() {
    return {};
  },
  methods: {
    goSearch() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "IsCouponProduct",
            value: true,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
@mixin publicBt {
  cursor: pointer;
  -webkit-cursor: pointer;
  border-radius: 32px;
  padding: 12px 0;
  text-align: center;
}
.van-collapse {
  ::v-deep .van-collapse-item__content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 20px;
  }
}
.couponList {
  width: 100%;
  .listBox {
    margin: 0% 3%;
    .item {
      padding: 15px;
      border-radius: 10px;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 15px;
      line-height: 50px;
      .itemTop {
        .itemTopTitle {
          width: 77%;
          font-size: 24px;
          flex: 1;
          -webkit-flex: 1;
          color: #000000;
          font-weight: bold;
        }
        .shelves {
          border: 1px solid #999999;
          width: 110px;
          text-align: center;
          border-radius: 32px;
          padding: 12px 0;
          color: #999999;
        }
        .putaway {
          border: 1px solid #ff625c;
          width: 110px;
          text-align: center;
          border-radius: 32px;
          padding: 15px 0;
          color: #ff625c;
        }
      }
    }
  }
  .infoBox {
    .infoBoxLeft {
      width: 77%;
      padding-right: 2.5%;
      margin-right: 2.5%;
      text-align: left;
      border-right: 2px dotted #01aaa3;
      .amount {
        color: #01aaa3;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        width: 30%;
        display: inline-block;
        vertical-align: middle;
        .symbol {
          font-size: 14px;
          margin-right: 5px;
        }
      }
      .amount-right {
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        .condition {
          color: #999;
          font-size: 12px;
          line-height: 2;
        }
        .date {
          color: #999;
          font-size: 12px;
          line-height: 2;
        }
      }
    }
    .infoBoxRigth {
      width: 20%;
      text-align: center;
      .doneBtn {
        font-weight: bold;
        border: none;
        padding: 0;
        vertical-align: middle;
        .van-button__text {
          font-size: 14px !important;
        }
      }
      .getBtn {
        color: #01aaa3;
        font-weight: bold;
        border: none;
        padding: 0;
        vertical-align: middle;
        .van-button__text {
          font-size: 14px !important;
        }
      }
    }
  }
  .usedBox {
    .usedBoxLeft {
      width: 77%;
      padding-right: 2.5%;
      margin-right: 2.5%;
      text-align: left;
      border-right: 2px dotted #999;
      .amount {
        color: #999;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        width: 30%;
        display: inline-block;
        vertical-align: middle;
        .symbol {
          font-size: 14px;
          margin-right: 5px;
        }
      }
      .amount-right {
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        .condition {
          color: #999;
          font-size: 12px;
          line-height: 2;
        }
        .date {
          color: #999;
          font-size: 12px;
          line-height: 2;
        }
        .shareTo {
          color: #999;
          font-size: 10px;
          line-height: 2;
        }
      }
    }
    .usedBoxRigth {
      width: 20%;
      text-align: center;
      .usedTxt {
        color: #999;
        font-weight: bold;
        vertical-align: middle;
        font-size: 14px;
      }
    }
  }
}
</style>
